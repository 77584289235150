import React from "react"
import { Link } from "gatsby"

const VantagePerks = ({ VPerksenhance, VPerkspremium }) => (
  <section className="perksContainer">
    <div className="flexItemJustifyCenter my-12 h-auto min-h-5rem w-full">
      <div className="perksPulseInnerContainer">
        <div className="px-6 py-4">
          <h1 className="pulsePackageDeal">Package Deal</h1>
          <div className="flex items-center h-auto">
            <h1 className="premiumPriceValue">$125</h1>{" "}
            <p className="premiumDuration">&nbsp; / Per month</p>
          </div>
          <p className="perksRate">$7 per user per month</p>
          <p className="text-lg font-medium text-gray-800">
            One-time Implementation cost applicable
          </p>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-full px-6">
            <Link to="/request-demo/" className="vc-colored-btn">
              Choose Package
            </Link>
          </div>
        </div>
        <div className="mt-4 w-full text-center">
          <p className="text-purple-900">
            <a href="#schedule-demo">Schedule a Demo</a>
          </p>
        </div>
        <div className="flexJustifyCenter -mb-8">
          <div className="p-0 w-full mb-8 mt-4">
            <div className="h-full p-6 flex flex-col relative overflow-hidden">
              <p className="flexItemCenter text-gray-500 mb-4">
                <span className="perksBulletPoints">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    strokewidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Cashback Offers
              </p>
              <p className="flexItemCenter text-gray-500 mb-4">
                <span className="perksBulletPoints">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    strokewidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Exclusive Corporate Offers
              </p>
              <p className="flexItemCenter text-gray-500 mb-4">
                <span className="perksBulletPoints">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    strokewidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Gift Card Redemption
              </p>
              <p className="flexItemCenter text-gray-500 mb-4">
                <span className="perksBulletPoints">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    strokewidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Internal Classfields
              </p>
              <p className="flexItemCenter text-gray-500 mb-4">
                <span className="perksBulletPoints">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    strokewidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Financial Wellness Packages
              </p>
              <p className="flexItemCenter text-gray-500 mb-4">
                <span className="perksBulletPoints">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    strokewidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Admin Dashboard
              </p>
              <p className="flexItemCenter text-gray-500 mb-4">
                <span className="perksBulletPoints">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    strokewidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Instant Notifications
              </p>
              <p className="flexItemCenter text-gray-500 mb-4">
                <span className="perksBulletPoints">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    strokewidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Wishlist
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default VantagePerks
