import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';


// const data = {
//     rows: [
//         {
//             title: "How can I apply for any job profiles in Vantage Circle?",
//             content: <div><p>Thanks for asking, to apply with us, you need to visit our careers page for relevant profiles. If anyone of the listed job profile is of your interest or relevant to your skills and experience, you can apply by clicking on the <strong>APPLY FOR THIS JOB</strong> button after going through the Job Description.</p>
//             <p>Upon clicking on the <strong>APPLY FOR THIS JOB</strong> button, you will be redirected to our career portal. You will have to fill out the application form with your details and attach the resume or cover letter.</p></div>,
//         },
//         {
//             title: "Can I apply for more than one job?",
//             content:
//                 <p >Yes, you can apply for more than one job but we would advise you to apply to a similar or relevant job profile.</p>,
//         },
//         {
//             title: "Why can’t I apply for the same job profile twice?",
//             content: <div> <p>You cannot apply twice for the same profile using the same email address and phone number. However, if you had an issue with the first application, you can apply again but with a different email address and phone number.</p>
//             <p>We do suggest that you apply once and recheck your application before submitting it to ensure a smooth process.</p></div>,
//         },
//         {
//             title: "What happens after I submit my application? What is the package version",
//             content: <div> <p>After you submit your application, the recruiting team will be checking the resumes, and based on the relevance with the job role, your application will be processed.</p>
//             <p className="mb-0">If shortlisted, you will receive an email from us to continue for further process.</p></div>,
//         },
//         {
//             title: "How much time does it take to process the applications?",
//             content:  <p>Our recruiters are trying their best to ensure a smooth and fast process. However, due to the large volume of applications and multiple openings in our organization, it might take up to 30 to 45 days to process the application.</p>
//         },
//         {
//             title: "How will I know if my application is shortlisted or rejected?",
//             content: 
//                 <div>
//                     <p>Once you submit your application, the process of shortlisting is started. The candidates who are shortlisted will receive a call or an email from our end. You will be given certain tasks or called for an interview based on which you will be assessed for further process.</p>
//                     <p>If your application is rejected, you will be receiving an email from our career portal <strong>no-reply@email.keka-mail.com</strong> mentioning the same.</p>
//                     <p className="mb-0">However, sometimes our mails can be forwarded to the <strong>Spam</strong> section, so kindly check your emails regularly after you submit your application.</p>
//                 </div>
//         },
//         {
//             title:'Is remote working available in Vantage Circle?',
//             content: 
//             <p className="mb-0">Currently, due to the COVID scenario in the country, our employees are working remotely. However, we don’t offer permanent remote working.</p>
//         },
//         {
//             title: 'How will I know the reason for rejecting my application?',
//             content:
//             <div>
//                 <p>Glad, you asked that. The applications are rejected based on the criterias that are set for the candidate we are looking for. </p>
//                 <p>Your application can be rejected if your profile is not relevant to the job description, or you don't meet the given criterias required for the profile.</p>
//                 <p className="mb-0">Also, the applications are rejected if you submit an incomplete profile or did not submit the resume.</p>
//             </div>
//         },
//         {
//             title: 'Can I refer someone?',
//             content :
//             <div>
//                 <p>Yes we do welcome referrals. We would suggest that the referred candidate himself or herself will have to apply from their own email id and contact number with the resume on our career portal.</p>
//                 <p>Also, the candidate can mention the name of the individual who has referred him/her to apply in Vantage Circle, while filling the application form.</p>
//                 <p className="mb-0">Referral can also be any employee of Vantage Circle with whom you are acquainted with.</p>
//             </div>
//         },
//         {
//             title: 'How will I be updated about the jobs in Vantage Circle?',
//             content :
//             <div>
//                 <p>To stay updated about jobs in our organization, you need to check our <a href="/careers/">Careers</a> page regularly. You can also stay updated about new profiles by following our <a href="https://www.linkedin.com/company/vantage-circle/">LinkedIn</a> , <a href="https://www.instagram.com/vantagecircle/">Instagram</a> or <a href="https://www.facebook.com/vantagecircle">Facebook</a> page.</p>
//                 <p className="mb-0">Also, you can check other job portals like Assam Careers, sakori.org, Naukri, Yuvajobs etc.</p>
//             </div>
//         }
        
//     ],
// };


// const styles = {
//     // bgColor: 'white',
//     titleTextColor: "#654AB7",
//     rowTitleColor: "#313f64",
//     // rowContentColor: 'grey',
//     arrowColor: "#654AB7",
// }

// const config = {
//     animate: true,
//     arrowIcon: <svg class="h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>,
//     tabFocus: true,
// };


// const Faqs = () => {
//     return (
//         <div>
//               <Faq
//                 data={data}
//                 styles={styles}
//                 config={config}
//             />
//         </div>
//     )
// }

const Faqs = () => {
    return(
                <div className="career-faq bg-purple-50 p-5 md:p-14">
                   <Accordion preExpanded={['a']}>
                        <AccordionItem uuid="a">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                1. Do your points expire?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>No, you need not worry about the point expiration.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                2. What is the subscription cost?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>The subscription cost depends on the module and plans you choose.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                3. How do we select the best plan for our company?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>The best plan could be chosen depending on the employee size and requirements.</p>

                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                4. On what the subscription cost depends? 
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>The subscription cost is charged based on two costs: a one-time setup cost and a monthly charge.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                5. Can we opt for a trial? 
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>Yes, you can definitely opt for a trial. </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                6. Do I have to pay extra for integration to my existing HRIS? 
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>No, we will include your existing HRIS into the setup, and it will be covered under the one-time setup charges.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                7. Do I need to pay extra for the Service Yearbook? 
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>Yes, Service Yearbook is a part of the premium version of Vantage Rewards.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                8. As an organization, can we choose the time duration of the invoicing and payment?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>Yes, the invoicing and the payments could be categorized on quarterly, half-yearly, or yearly terms.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                9. Can I opt for a full basket of all the tools? 
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                We do have a comprehensive package with Vantage Perks, Vantage Rewards, Vantage Fit, and Vantage Pulse. To know more please contact us here.
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion> 
              </div>
    )
}

export default Faqs
