import React from "react";
import ReactDOM from "react-dom";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup} from 'pure-react-carousel'

const Speakers = () => {
    return (
        <section class ="spkr-slider slanted-bg flex flex-col justify-center items-center bg-gray-100 w-full p-3 h-auto" id="bio">
                <div class = "flex flex-col justify-center items-start w-5/6 md:w-4/6 mt-20">
                    <h2 class = "w-full flex justify-center font-bold text-center text-3xl md:text-4xl text-gray-800 mb-5">About the<span class = "text-orange ">&nbsp;Speakers</span></h2>
                    <CarouselProvider className="w-full relative mx-auto"
                            naturalSlideWidth={100}
                            naturalSlideHeight={55}
                            totalSlides={8}
                        >
                            {/* <div className="quote-icon1 relative">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/05/quotation-mark.svg" alt="quote-icon" />
                            </div> */}
                            <Slider className="rounded-lg bg-gray-100 mt-14">
                                <Slide index={0}>
                                    <div className="w-full py-3 px-2 md:p-10 carouselcard">
                                        <div class = "w-full flex justify-center items-start mb-5"><img class="rounded-full" src= "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/09/Speaker-4.png" alt= "speaker name"/></div>
                                        <div class= "text-center md:text-left w-full">
                                            <h2 class ="font-bold text-3xl text-gray-800 text-center">Chester Elton</h2>
                                            <p class = "text-xl text-gray-800 pt-1 pb-2 text-center">#1 Bestselling Business Author, Organizational Culture, Employee Engagement and Teamwork Expert</p>
                                            <p class = "h-auto text-center">Chester Elton has spent two decades helping some of the world’s most successful businesses engage their employees to execute on strategy, vision, and values. 

                                                    Elton is co-author of multiple award-winning New York Times and #1 Wall Street Journal best selling leadership books including All In, The Carrot Principle, The Best Team Wins, and Anxiety at Work. He has appeared on NBC’s Today, CNN, ABC, MSNBC, National Public Radio and CBS’s 60 Minutes. 
                                            </p>
                                        </div>
                                    </div>
                                </Slide>
                                <Slide index={1}>
                                    <div className="w-full py-3 px-2 md:p-10 carouselcard">
                                        <div class = "w-full flex justify-center items-start mb-5"><img class="rounded-full" src= "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/09/Speaker-3.png" alt= "speaker name"/></div>
                                        <div class= "text-center md:text-left w-full">
                                            <h2 class ="font-bold text-3xl text-gray-800 text-center">Enrique Rubio</h2>
                                            <p class = "text-xl text-gray-800 pt-1 pb-2 text-center">Founder of Hacking HR, Keynote Speaker & Top 100 Global HR Influencer</p>
                                            <p class = "h-auto text-center">Enrique is a Fulbright Scholar, has over twenty years of experience and is an Electronic Engineer with an Executive Master’s in Public Administration from Maxwell School at Syracuse University.

                                                He  was the founder and CEO at Management Consultants, a firm specialized in Human Resources in Venezuela. Enrique is a guest author in several blogs about innovation, management and human resources. Most recently Enrique worked as an advisor to the Chief Human Resources Officer at the Inter-American Development Bank.
 
                                            </p>
                                        </div>
                                    </div>
                                </Slide>
                                <Slide index={2}>
                                    <div className="w-full py-3 px-2 md:p-10 carouselcard">
                                        <div class = "w-full flex justify-center items-start mb-5"><img class="rounded-full" src= "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/09/Speaker-6.png" alt= "speaker name"/></div>
                                        <div class= "text-center md:text-left w-full">
                                            <h2 class ="font-bold text-3xl text-gray-800 text-center">Pat Wadors</h2>
                                            <p class = "text-xl text-gray-800 pt-1 pb-2 text-center">Chief Talent Officer at Procore</p>
                                            <p class = "h-auto text-center">

                                                Pat Wadors currently serves as Chief Talent Officer for Procore, the digital workflow company focused on improving the lives of everyone in construction.

                                                Pat has worked as the Chief Talent Officer at ServiceNow, the Senior Vice President at Global Talent Organization at LinkedIn, and has also held human resources leadership positions at Plantronics, Yahoo!, Align Technology and Applied Materials.

                                                Pat currently serves on the boards of directors for Accolade and UKG (Ultimate Kronos Group). Pat is a renowned Keynote Speaker on HR, DE & I, Talent Management and Future of Work.
                                            </p>
                                        </div>
                                    </div>
                                </Slide>
                                <Slide index={3}>
                                    <div className="w-full py-3 px-2 md:p-10 carouselcard">
                                        <div class = "w-full flex justify-center items-start mb-5"><img class="rounded-full" src= "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/09/Tracy.png" alt= "speaker name"/></div>
                                        <div class= "text-center md:text-left w-full">
                                            <h2 class ="font-bold text-3xl text-gray-800 text-center">Tracie Sponenberg</h2>
                                            <p class = "text-xl text-gray-800 pt-1 pb-2 text-center">Chief People Officer at The Granite Group
                                                                    & Co-Founder at DisruptHR NH & HR Rebooted</p>
                                            <p class = "h-auto text-center">

                                            Tracie Sponenberg is Chief People Officer of The Granite Group. 

                                            She is SPHR and SHRM-SCP certified and was the recipient of the 2019 GSHRC Ry Perry HR Hero Award. She was named one of the 2020 “35 Employer Branding Experts” by Eightfold.ai; was named to the 2020 Most Inclusive Influencer List; and was recently named by PeopleHum as one of the Top 100 Global Thought Leaders, and one of the top 50 Global HR Professionals by The OnCon Icon Awards.
                                            </p>
                                        </div>
                                    </div>
                                </Slide>
                                <Slide index={4}>
                                    <div className="w-full py-3 px-2 md:p-10 carouselcard">
                                        <div class = "w-full flex justify-center items-start mb-5"><img class="rounded-full" src= "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/09/Liane.png" alt= "speaker name"/></div>
                                        <div class= "text-center md:text-left w-full">
                                            <h2 class ="font-bold text-3xl text-gray-800 text-center">Liane Davey</h2>
                                            <p class = "text-xl text-gray-800 pt-1 pb-2 text-center">Co-founder and Principal at 3COze Inc</p>
                                            <p class = "h-auto text-center">

                                            Liane Davey is the multi-talented Co-founder and Principal at 3COze Inc. She is the author of three New York Times best-sellers, including “The Good Fight: Use Productive Conflict to Get Your Team and Your Organization Back on Track” and “You First: Inspire Your Team to Grow Up, Get Along, and Get Stuff Done”.

                                                As the co-founder of 3COze Inc., she advises companies such as Amazon, TD Bank, Walmart, UNICEF, 3M, and SONY.

                                                Liane has a Ph.D. in Organizational Psychology and is affectionately known as the “Water Cooler Psychologist”.
                                            </p>
                                        </div>
                                    </div>
                                </Slide>
                                <Slide index={5}>
                                        <div className="w-full py-3 px-2 md:p-10 carouselcard">
                                            <div class = "w-full flex justify-center items-start mb-5"><img class="rounded-full" src= "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/09/KarinDavid.png" alt= "speaker name"/></div>
                                            <div class= "text-center md:text-left w-full flex-col flex justify-center items-center">
                                                <div class = "flex justify-around w-1/2"><h2 class ="font-bold text-3xl text-gray-800 text-center">Karin Hurt (CEO) & David Dye (President)</h2></div>
                                                <p class = "text-xl text-gray-800 pt-1 text-center">Let's Grow Leaders</p>
                                                <p class = "h-auto text-center">
                                                    Karin Hurt and David Dye help human-centered leaders resolve workplace ambiguity and chaos, so that they can drive innovation, productivity and revenue without burning out employees. As CEO and President of Let’s Grow Leaders, they are known for practical tools and leadership development programs that stick. <br/>

                                                    Karin and David are the award-winning authors of five books including, Courageous Cultures: How to Build Teams of Micro-Innovators, Problem Solvers, and Customer Advocates and Winning Well: A Manager’s Guide to Getting Results-Without Losing Your Soul. 


                                                </p>
                                            </div>
                                        </div>
                                </Slide>
                                <Slide index={6}>
                                    <div className="w-full py-3 px-2 md:p-10 carouselcard">
                                            <div class = "w-full flex justify-center items-start mb-5"><img class="rounded-full" src= "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/09/Speaker-1.png" alt= "speaker name"/></div>
                                            <div class= "text-center md:text-left w-full">
                                                <h2 class ="font-bold text-3xl text-gray-800 text-center">Garry Ridge</h2>
                                                <p class = "text-xl text-gray-800 pt-1 pb-2 text-center">Chairman & Chief Executive Officer at WD-40, and One of the World’s Top Leadership Keynote Speakers</p>
                                                <p class = "h-auto text-center">
                                                Garry O. Ridge is Chairman and CEO of WD-40 Company, where he has held various leadership positions before being appointed CEO in 1997. 

                                                He is also an adjunct professor at the University of San Diego where he teaches the principles and practices of corporate culture in the Master of Science in Executive Leadership program. 

                                                A native of Australia, Mr. Ridge holds a certificate in Modern Retailing and wholesale distribution and a Master of Science in Executive Leadership from the University of San Diego.
                                                </p>
                                            </div>
                                    </div>
                                </Slide>
                                <Slide index={7}>
                                    <div className="w-full py-3 px-2 md:p-10 carouselcard">
                                            <div class = "w-full flex justify-center items-start mb-5"><img class="rounded-full" src= "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/09/LWiseman.png" alt= "speaker name"/></div>
                                            <div class= "text-center md:text-left w-full">
                                                <h2 class ="font-bold text-3xl text-gray-800 text-center">Liz Wiseman</h2>
                                                <p class = "text-xl text-gray-800 pt-1 pb-2 text-center">Chief Executive Officer, The Wiseman Group</p>
                                                <p class = "h-auto text-center">
                                                    Liz Wiseman is the CEO of the Wiseman Group. She is a celebrated New York Times & Wall-Street Journal best-selling author.

                                                    Liz has been listed on the Thinkers50 ranking and in 2019 was recognized as the top leadership thinker in the world.

                                                    She is a frequent guest lecturer at BYU and Stanford University and is a former executive at Oracle Corporation, where she worked as the Vice President of Oracle University and as the global leader for Human Resource Development.
                                                </p>
                                            </div>
                                    </div>
                                </Slide>    
                            </Slider>
                            <ButtonBack className="shadow eb-carousel-btn absolute bottom-1/2 left-0 transform -translate-x-10 md:-translate-x-20 p-3 md:p-5 rounded-full">
                                <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
                            </ButtonBack>
                            <ButtonNext className="shadow eb-carousel-btn absolute bottom-1/2 right-0 transform translate-x-10 md:translate-x-20 p-3 md:p-5 rounded-full">
                                <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>
                            </ButtonNext>
                            <div class = "w-full flex justify-center items-center"><DotGroup /></div>
                        </CarouselProvider>
                </div>
            </section>
   
    

    )
};


const renderSpeakers=()=>{
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#speakers")
        if (id != null){
            ReactDOM.render(
                <Speakers />,
                id
            )
        }
    }
}

setTimeout(renderSpeakers,100)

export default Speakers;