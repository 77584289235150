import React from "react";
import ReactDOM from "react-dom";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup} from 'pure-react-carousel'

const Carousel = () => { 
    return (
        <CarouselProvider className="relative mx-auto"
            naturalSlideWidth={110}
            naturalSlideHeight={80}
            totalSlides={3}
        >
            <Slider className="">
                <Slide index={0}><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/global-leaders-1.png" alt="image1"/></Slide>
                <Slide index={1}><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/global-leaders-2.png" alt="image2"/></Slide>
                <Slide index={2}><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/global-leaders-3.png" alt="image3"/></Slide>
            </Slider>
            <ButtonBack className="shadow eb-carousel-btn">
                <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
            </ButtonBack>
            <ButtonNext className="shadow eb-carousel-btn">
                <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>
            </ButtonNext>
            <DotGroup />
        </CarouselProvider>
    )
}

const renderEBook=()=>{
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#ebook1")
        if (id != null){
            ReactDOM.render(
                <Carousel />,
                id
            )
        }
    }
}

setTimeout(renderEBook,100)

export default Carousel;

