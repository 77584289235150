import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TestimonialsEn from "../components/Testimonials/TestimonialsEn"
import TestimonialsFr from "../components/Testimonials/TestimonialsFr"
import TestimonialsBrandsEn from "../components/TestimonialsBrands/TestimonialsBrandsEn"
import TestimonialsBrandsFr from "../components/TestimonialsBrands/TestimonialsBrandsFr"
import RequestDemoTestimonial from "../components/Testimonials/RequestDemoTestimonial"
import CHPCalc from "../tools/CHP-calculator"
import PercentageCircle from "../components/PercentageCircle"
import { useLanguages } from "../hooks/useLanguages"
import PricingEn from "../components/Pricing/PricingEn"
import PricingFr from "../components/Pricing/PricingFr"
import PricingEs from "../components/Pricing/PricingEs"
import HubspotForm from "react-hubspot-form"
import Speakers from "../components/Speakers"
import AwardMentions from "../components/AwardMentions"
import Ratings from "../components/HomepageContent/Ratings"
import WebcastCarousel from "../components/WebcastCarousel/WebcastCarousel"
import Ebook from "../components/EBookCarousels/EBook1"
import MSTeamCarousel from "../components/Carousels/MsTeamCarousel"
import WebcastCarouselIndia from "../components/WebcastCarousel/WebcastCarouselIndia"
import BottomBanner from "../components/BottomBanner"
import AOS from "aos"
import "aos/dist/aos.css"
import loadable from "@loadable/component"
import EmbedVideoFrame from "../components/EmbedVideoFrame"
import CareerTestimonials from "../components/Testimonials/CareerTestimonials"
// import BacktoTop from "../components/BacktoTop"
import CheckTimelinePic from "../components/Testimonials/CheckTimelinePic"
import Feedback from "../components/Feedback"
import ClientsModule from "../components/Homepage2023Content/ClientsModule"
import GlobalCapabilities from "../components/GlobalCapabilities"
import ProductVideoComp from "../components/ProductVideoComp"
import V3Features from "../components/V3Features"
import ClientsFeedbackCarousel from "../components/Carousels/ClientsFeedbackCarousel"
import AwardBadges from "../components/AwardBadges"
import Flipper from "../components/Flipper"
import PricingPageAccordion from "../components/PricingPageAccordion"
import FAQ from "../components/FAQ"

export const query = graphql`
  query($id: ID!) {
    wpcontent {
      page(id: $id) {
        uri
        id
        language {
          slug
        }
        translations {
          uri
          slug
        }
        seo {
          metaDesc
          title
          metaRobotsNoindex
        }
        content
      }
    }
    site(siteMetadata: {}) {
      id
      siteMetadata {
        siteUrl
        supportedLanguages
        title
      }
    }
  }
`
const getLinks = (host, originalPath, translations, languageSlug) => {
  const links = []

  // alert(languageSlug)

  links.push({
    rel: "canonical",
    href: `${host}${originalPath}`,
  })

  if (originalPath !== null && translations !== undefined) {
    if (originalPath === "/") {
      links.push({
        rel: "alternate",
        hreflang: `en`,
        href: `${host}${originalPath}`,
      })
    } else {
      links.push({
        rel: "alternate",
        hreflang: `${languageSlug}`,
        href: `${host}${originalPath}`,
      })
    }
  }

  if (translations[0] === undefined || translations[0] === null) return links

  if (typeof translations !== "string") {
    if (translations[0].language !== null || translations[0].slug !== null) {
      translations.forEach(ele => {
        if (ele == null) return
        if (ele.slug !== null && ele.language !== undefined) {
          if (ele.language.slug === "en") {
            if (ele.slug === "index") {
              links.push({
                rel: "alternate",
                hreflang: `${ele.language.slug}`,
                href: `${host}/`,
              })
            } else {
              links.push({
                rel: "alternate",
                hreflang: `${ele.language.slug}`,
                href: `${host}${ele.uri}`,
              })
            }
          } else if (
            ele.language.slug !== null &&
            ele.slug !== "index" &&
            ele.language.slug === ele.slug
          ) {
            links.push({
              rel: "alternate",
              hreflang: `${ele.language.slug}`,
              href: `${host}/${ele.language.slug}/`,
            })
          }
          //todo make the language dynamic
          else if (ele.language.slug !== null && ele.uri !== null) {
            links.push({
              rel: "alternate",
              hreflang: `${ele.language.slug}`,
              href: `${host}${ele.uri}`,
            })
          }
        }
      })
    }
  }
  return links
}

const PageTemplate = ({ data, location, pageContext }) => {
  useEffect(() => {
    AOS.init({
      once: true,
    })
  }, [])


  //to show/hide exit intent
  // const [exitintent, setExitintent] = useState(false)

  const pageLanguage = pageContext.lang?.toLowerCase()

  // const closepopup = () => {
  //   setExitintent(false)
  //   localStorage.setItem("exitPopup", false)
  // }

  // const pathname = location?.pathname ? location.pathname : ""

  // const handleMouseLeave = event => {
  //   if (checkIfCookieIsPresent()) {
  //     return
  //   }
  //   if (!checkIfCookieIsPresent()) {
  //     setExitintent(true)
  //     setCookieForExitIntent()
  //   }
  // }

  // const checkIfCookieIsPresent = () => {
  //   let availableCookies = document.cookie
  //   if (
  //     availableCookies.split(";").indexOf(" showExitIntent=true") !== -1 ||
  //     availableCookies.split(";").indexOf("showExitIntent=true") !== -1
  //   ) {
  //     return true
  //   } else return false
  // }

  // const setCookieForExitIntent = () => {
  //   let now = new Date()
  //   now.setTime(now.getTime() + 24 * 3600 * 1000)
  //   let cookieName = "showExitIntent"
  //   let cookieValue = "true"

  //   document.cookie = `${cookieName}=${cookieValue}; expires=${now.toUTCString()}; path=/`
  // }

  // const excludedPathForExitPopup = [
  //   "/employee-rewards-and-recognition-platform-v1/",
  //   "/employee-rewards-and-recognition-program-v1/",
  //   "/employee-rewards-and-recognition-platform-v1/",
  //   "/employee-rewards-and-recognition-program-v1/",
  //   "/employee-rewards-and-recognition-program-v2/",
  //   "/employee-rewards-and-recognition-program-v3/",
  //   "/employee-rewards-and-recognition-platform-v2/",
  //   "/employee-rewards-and-recognition-platform-v3/",
  //   "/employee-recognition-program-v4/",
  //   "/request-demo/",
  //   "/schedule-demo/",
  //   "/thank-you-page-ads-program/",
  //   "/thank-you-page-ads-platform/",
  //   "/vantage-point-webcast-registration-in/",
  //   "/aire-framework-whitepaper-for-conference-attendees/",
  //   // "/products/rewards-recognition/",
  //   "/vantage-point-webcast-registration/",
  //   "/vantage-point-webcast-live/",
  //   "/corporate-diwali-offers/",
  //   "/privacy-policy-vc/",
  //   "/terms-and-conditions-vc/",
  //   "/fr/*",
  //   "/es/*",
  //   "/de/*",
  //   "/nl/*",
  //   "/pt/*",
  // ]

  // useEffect(() => {
  //   console.log("checkifcookieco", checkIfCookieIsPresent())
  //   let timeOut = 0
  //   if (excludedPathForExitPopup.includes(pathname)) return
  //   else {
  //     if (typeof window !== "undefined") {
  //       timeOut= setTimeout(() => {
  //         handleMouseLeave()
  //       }, 3000);
  //     }
  //   }
  //   return () => {
  //     clearTimeout(timeOut)
  //   }
  // }, [])

  const pageContent = data.wpcontent.page.content
  const metaDesc = data.wpcontent.page.seo.metaDesc
  const pageTitle = data.wpcontent.page.seo.title
  const metaIndex = data.wpcontent.page.seo.metaRobotsNoindex
  const host = data.site.siteMetadata.siteUrl
  const originalPath = data.wpcontent.page.uri
  const languageSlug = data.wpcontent.page.language?.slug
  const translations = data.wpcontent.page.translations || []
  const [currLanguage] = useLanguages()

  // const renderExitIntent = () => {
  //   return (
  //     <div className={`${exitintent ? "block" : "hidden"}`}>
  //       {currLanguage === "en" && (
  //         <ExitIntent exitintent={exitintent} closepopup={closepopup} />
  //       )}
  //       {currLanguage === "fr" && (
  //         <ExitIntentFr exitintent={exitintent} closepopup={closepopup} />
  //       )}
  //       {currLanguage === "es" && (
  //         <ExitIntentEs exitintent={exitintent} closepopup={closepopup} />
  //       )}
  //       {currLanguage === "de" && (
  //         <ExitIntentDe exitintent={exitintent} closepopup={closepopup} />
  //       )}
  //       {currLanguage === "nl" && (
  //         <ExitIntentNl exitintent={exitintent} closepopup={closepopup} />
  //       )}
  //       {currLanguage === "pt" && (
  //         <ExitIntentPt exitintent={exitintent} closepopup={closepopup} />
  //       )}
  //     </div>
  //   )
  // }

  return (
    <Layout location={location} pageLanguage={languageSlug}>
      <SEO
        title={pageTitle}
        description={metaDesc}
        link={getLinks(host, originalPath, translations, languageSlug)}
        meta={[
          {
            name: `robots`,
            content: metaIndex,
          },
          {
            name: `description`,
            content: metaDesc,
          },
          {
            property: `og:title`,
            content: pageTitle,
          },
          {
            property: `og:description`,
            content: metaDesc,
          },
          {
            name: `twitter:title`,
            content: pageTitle,
          },
          {
            name: `twitter:description`,
            content: metaDesc,
          }
        ]}
      />
      <div dangerouslySetInnerHTML={{ __html: pageContent }} />
      {/* {!excludedPathForExitPopup.includes(pathname) && currLanguage == "en" && (
        <BottomBanner />
      )} */}
    </Layout>
  )
}

export default PageTemplate
