import React from "react"
import { Link } from "gatsby"
// import Helmet from "react-helmet";
// import { useState } from "react";

const VantageRewards = ({ VRpremium, VRenhance, activeTab, duration }) => {
  return (
    <section className="container px-0 md:px-2 pt-10 pb-5 mx-auto w-full lg:w-4/6">
      {/*<div className="text-center py-20 text-lg font-bold">Pricing Slider</div>*/}

      {/* Designing monthly and yearly tabs */}
      <div className="flex flex-col text-center w-full mb-10">
        <div className="flex mx-auto p-1 transform scale-105 bg-purple-500 rounded-full overflow-hidden mt-6 relative">
          <button
            onClick={activeTab}
            id="monthly"
            className="z-10 text-sm text-white font-semibold py-3 px-8 text-white focus:outline-none"
          >
            Pay Monthly
          </button>
          <button
            onClick={activeTab}
            id="yearly"
            className="z-10 py-3 px-8 text-sm text-white font-semibold focus:outline-none"
          >
            Pay Yearly
          </button>
          <div
            className={
              duration === "yearly"
                ? "absolute left-1 bottom-1/6 w-1/2 bg-white rounded-full z-20 h-5/6 activeTab flex justify-center items-center"
                : "flex justify-center items-center absolute left-1 bottom-1/6 w-1/2 bg-white inactiveTab rounded-full z-20 h-5/6"
            }
          >
            <h4 className="capitalize text-sm font-bold">Pay {duration}</h4>
          </div>
        </div>
        <p className="text-sm mt-6 text-gray-900">
          Choose yearly plan and save{" "}
          <span className="text-orange font-semibold">30% off</span>
        </p>
      </div>

      {/*Designing the price cards*/}
      {/*Premium*/}
      <div className="flex flex-wrap justify-center">
        <div className="p-0 md:p-2 md:w-1/2 w-full">
          <div className="h-full p-6 rounded-lg shadow-lg flex flex-col relative overflow-hidden">
            <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
              Premium
            </h2>
            <div className="flex items-center h-auto">
              <h3 className="text-4xl my-2 text-gray-900 font-semibold">
                ${VRpremium}
              </h3>{" "}
              <p className="text-base font-medium mb-0 text-gray-900">
                &nbsp; / Per month
              </p>
            </div>
            <p className="mb-8 mt-3 md:mb-5 md:mt-0">$3 per user per month</p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Automated Rewarding
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Social Recognition
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Colleague Nominations
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Advanced Admin Roles
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Award Workflow
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Badges and Awards Configuration
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Launch Material
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Analytics Dashboard
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Point-based Rewards
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Gift Card Redemption
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Global Points Management
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Bulk Upload via CSV
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Global Catalogue
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Custom Reporting
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Priority Support
            </p>
            <div className="mt-5 mb-4 sm:mt-8 md:mt-10 flex justify-center">
              <div>
                <Link
                  to="#schedule-demo"
                  className="vc-colored-btn text-sm md:text-base"
                >
                  Schedule a Free Demo
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/*Enhanced*/}
        <div className="p-0 md:p-2 mt-6 md:mt-0 md:w-1/2 w-full">
          <div className="h-full p-6 rounded-lg shadow-lg bg-purple-500 text-white flex flex-col relative overflow-hidden">
            <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
              Enhanced
            </h2>
            <div className="flex items-center h-auto">
              <h2 className="text-4xl my-2 text-white font-semibold">
                ${VRenhance}
              </h2>{" "}
              <p className="text-base font-medium mb-0 text-white">
                &nbsp; / Per month
              </p>
            </div>
            <p className="text-purple-10">$7 per user per month</p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-10 text-purple-500 rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              <p className="text-purple-50 mb-0">
                Includes everything in pricing plus
              </p>
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-10 text-purple-500 rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              <p className="text-purple-50 mb-0">Service Yearbook</p>
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-10 text-purple-500 rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              <p className="text-purple-50 mb-0">Customized Workflow</p>
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-10 text-purple-500 rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              <p className="text-purple-50 mb-0">White Label Solution</p>
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-10 text-purple-500 rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              <p className="text-purple-50 mb-0">SSO Integration</p>
            </p>
            <p className="flex items-center text-gray-500 mb-4">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-purple-10 text-purple-500 rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              <p className="text-purple-50 mb-0">Dedicated Account Manager</p>
            </p>
            <div className="mt-5 sm:mt-8 md:mt-10 flex justify-center">
              <div>
                <Link
                  to="#schedule-demo"
                  className="vc-white-btn text-sm md:text-base"
                >
                  Schedule a Free Demo
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VantageRewards
