import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"

const RequestDemoTestimonial = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [isMobile, setIsMobile] = useState(false)

  const tabs = [
    {
      label: "What Tata Motors Said",
      label2: "What Tata Motors is saying",
      content:
        " Building a highly engaged workforce is integral to Tata Motors' vision. One of the important pillars for fostering engagement is to celebrate contributions and milestones. This can be best achieved by real-time recognition and celebration. Vantage Circle has been a wonderful partner as we developed our Digital Rewards & Recognition program - Accolades. We value our collaboration with Team Vantage and look forward to working together on offering great experience to our employees.",
      logo:
        "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Tatamotors.webp",
      perImage:
        "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Nitin-Sansare.jpeg",
      name: "Nitin Sansare",
      position: "Head – Performance Management, Compensation and Benefits",
    },

    {
      label: "What Adecco Said",
      label2: "What Adecco is saying",
      content:
        " We really like the way that Vantage Circle has created a wave of family spirit within Adecco and the way the platform serves all our colleagues across the branches and it gets everyone together. The rewards points given to our employees and they are free to choose wherever they want to spend their points, which is a big win for us. Vantage Circle has done a great job for Adecco and I would definitely recommend it for other companies as well.",
      logo:
        "https://gatsycms.vantagecircle.com/wp-content/uploads/2023/05/Adecco.webp",
      perImage:
        "https://res.cloudinary.com/vantagecircle/image/upload/c_thumb,g_face,h_250,w_250/v1631098362/gatsbycms/uploads/2023/05/Bani-Sodhi.jpeg",
      name: "Bani Sodhi",
      position: "Human Resource",
    },
    {
      label: "What Wipro  Said",
      label2: "What Wipro is saying",
      content:
        " Wipro Advantage (powered by Vantage Circle) was launched last year to provide excusive privileges to our employees pan India. The feedback received from the employees has been very positive and we feel it is an amazing value proposition for our employees.",
      logo:
        "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/06/rnr-cx-logos-wipro-1.webp",
      perImage:
        "https://res.cloudinary.com/vantagecircle/image/upload/c_thumb,g_face,h_250,w_250/v1631098362/gatsbycms/uploads/2023/05/Samir-Gadgil.jpeg",
      name: "Samir Gadgil",
      position: "Vice President and HR Head",
    },
    {
      label: "What Infosys Said",
      label2: "What Infosys is saying",
      content:
        " We are very happy to be associated with InfyGold+, a part of Rewards and Recognition Program at Infosys and we run this program with Vantage Circle. It has been awesome to have worked with Vantage Circle and the team has helped us provide great experiences to our employees through a single platform, which is easy to use, provides a gamut of options and most importantly, is available on personal smartphones throught the app.",
      logo:
        "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/06/rnr-cx-logos-infosys.webp",
      perImage:
        "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/shruthi-bopaiah.webp",
      name: "Shruthi Bopaiah",
      position: "AVP and Head of Internal Communication",
    },
    {
      label: "What Tata Power Said",
      label2: "What Tata Power is saying",
      content:
        " Vantage Circle Platform was implemented to  support existing Reward & Recognition Process. Vantage Reward Points can be availed easily by Employees providing complete freedom to choose from various bouquet of Services and Online options available as per the individual choices (have personalized experience). The online platform made it easier to have instant gratification possible. This Platform is now widely used across all the Functions / Locations. Reward Points are also being given for any Competitions or driving New Initiatives with Spot Prizes given to the Winners. Vantage Reward Points helped in boosting Employees morale even during the pandemic situation. We look forward to a long term partnership with Vantage Circle exploring other features to further enhance R&R Function at Tata Power.",
      logo:
        "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/Tatapower.webp",
      perImage:
        "https://res.cloudinary.com/vantagecircle/image/upload/c_thumb,g_face,h_250,w_250/v1631098362/gatsbycms/uploads/2023/05/Vimmi-Mehta.jpeg",
      name: "Vimmi Mehta",
      position: "Head – R&R & Quality Improvement",
    },
  ]

  const handleTabClick = index => {
    if (!isMobile) {
      setActiveTab(index)
    } else {
      setActiveTab(prevActiveTab => (prevActiveTab === index ? null : index))
    }
  }

  const renderTabs = () => {
    if (isMobile) {
      return (
        <ul className="space-y-2 w-3/4 mx-auto">
          {tabs.map((tab, index) => (
            <li key={index} className="relative">
              <button
                className={`flex items-center justify-center w-full py-2 px-4 focus:outline-none shadow-md rounded-md bg-white ${
                  activeTab === index ? "bg-sand text-sm font-semibold " : ""
                }`}
                onClick={() => handleTabClick(index)}
              >
                {activeTab === index ? tab.label2 : tab.label}
                <span>
                  {activeTab === index ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="bi bi-chevron-up h-5 w-5  text-gray-400 group-hover:text-gray-500 absolute right-3 top-3"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="bi bi-chevron-down h-5 w-5  text-gray-400 group-hover:text-gray-500 absolute right-3 top-4"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  )}
                </span>
              </button>
              {activeTab === index && (
                <div className="p-4 shadow-md my-8 bg-newSand text-center rounded-lg ">
                  <div className="flex justify-end">
                    <img className="w-1/4" src={tab.logo} alt="" />
                  </div>
                  <div className="flex flex-col justify-center mb-2">
                    <div className="flex justify-center w-full">
                      <img
                        className="rounded-full border-2 w-2/5 "
                        src={tab.perImage}
                        alt="Company Head"
                      />
                    </div>
                    <div className="text-sm font-semibold my-2">{tab.name}</div>
                    <div className="flex flex-col">
                      <p className="m-0 text-xs">{tab.position}</p>
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-start">
                      <img
                        className="w-1/12"
                        src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2023/01/quote.webp"
                        alt=""
                      />
                    </div>
                    <p className="my-2 text-justify text-sm tracking-tight leading-5">
                      {tab.content}
                    </p>
                    <div className="flex justify-end">
                      <img
                        className="w-1/12 transform rotate-180"
                        src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2023/01/quote.webp"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      )
    } else {
      return (
        <div className="flex items-center gap-x-28 mt-20">
          <div className="w-8/12 ml-24">
            {activeTab !== null && (
              <div className="p-4 bg-newSand rounded-xl shadow-md ">
                <div className="flex justify-end mr-4">
                  <img className="w-1/12" src={tabs[activeTab].logo} alt="" />
                </div>
                <div className="flex w-full gap-x-8">
                  <div className="flex flex-col justify-center items-center mb-2 w-4/12">
                    <div className="flex justify-center w-full">
                      <img
                        className="rounded-full border-2 w-3/5 "
                        src={tabs[activeTab].perImage}
                        alt="Company Head"
                      />
                    </div>
                    <div className="text-lg font-semibold mt-2">
                      <h1>{tabs[activeTab].name}</h1>
                    </div>
                    <div className="flex flex-col">
                      <p className="m-0 text-center text-sm">
                        {tabs[activeTab].position}
                      </p>
                    </div>
                  </div>
                  <div className="w-8/12">
                    <div className="flex justify-start">
                      <img
                        className="w-2/12"
                        src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2023/01/quote.webp"
                        alt=""
                      />
                    </div>
                    <p className="mt-4">{tabs[activeTab].content}</p>
                    <div className="flex justify-end">
                      <img
                        className="w-2/12 transform rotate-180"
                        src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2023/01/quote.webp"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <nav className="flex flex-col space-y-5 justify-end w-4/12 ">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`py-6 px-4 my-1 text-2xl focus:outline-none bg-white rounded-l-xl shadow-md hover:transform hover:scale-110 hover:origin-right hover:bg-sand transition duration-300	${
                  activeTab === index
                    ? "bg-sand text-3xl font-medium border-indigo-500 transform scale-110 origin-right "
                    : ""
                }`}
                onClick={() => handleTabClick(index)}
              >
                {activeTab === index ? tab.label2 : tab.label}
              </button>
            ))}
          </nav>
        </div>
      )
    }
  }

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    checkIsMobile()
    window.addEventListener("resize", checkIsMobile)

    return () => {
      window.removeEventListener("resize", checkIsMobile)
    }
  }, [])

  return (
    <div className="bg-lightSand pt-5 lg:pt-20 pb-10 lg:pb-28">
      <div className="flex justify-center text-xl lg:text-5xl mb-10">
        <h1>
          Stories of <span style={{ color: "#ff6d05" }}>Transformation</span>{" "}
        </h1>
      </div>
      <div className="flex justify-between">
        <div className="w-full">{renderTabs()}</div>
      </div>
    </div>
  )
}

const renderRequestDemoTestimonial = () => {
  if (typeof window !== "undefined") {
    let id = document.querySelector("#requesttestimonial")
    if (id != null) {
      ReactDOM.render(<RequestDemoTestimonial />, id)
    }
  }
}

setTimeout(renderRequestDemoTestimonial, 100)

export default RequestDemoTestimonial
