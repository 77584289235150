import React from "react";
import ReactDOM from "react-dom";
// import Layout from '../components/layout'
import HubspotForm from 'react-hubspot-form'
// import Helmet from "react-helmet"
// import AwardMentions from "../components/AwardMentions"
import VantageRewards from "../ProductsPricing/En/VantageRewards"
import VantagePerks from "../ProductsPricing/En/VantagePerks"
import VantagePulse from "../ProductsPricing/En/VantagePulse"
import VantageFit from "../ProductsPricing/En/VantageFit"
import { useState } from 'react'
import { Listbox } from '@headlessui/react'
import Faqs from '../ProductsPricing/En/Faqs'
// import Slider, { Range } from 'rc-slider';
import Slider, { createSliderWithTooltip, Range } from "rc-slider";

const PricingEn = () => {
 
    const products = [
        { id: 1, name: 'Vantage Rewards', logo : 'https://res.cloudinary.com/vantagecircle/image/upload/w_30/vantagecms/uploads/2020/08/vantagerewards-icon.png', unavailable: false },
        { id: 2, name: 'Vantage Perks', logo : 'https://res.cloudinary.com/vantagecircle/image/upload/w_30/vantagecms/uploads/2020/08/vantageperks-icon.png', unavailable: false },
        { id: 3, name: 'Vantage Pulse', logo : 'https://res.cloudinary.com/vantagecircle/image/upload/w_30/vantagecms/uploads/2020/08/vantagepulse-icon.png', unavailable: false },
        { id: 4, name: 'Vantage Fit', logo : 'https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/vantagefit-logo.png', unavailable: false },
    ]

    const [selectedProduct, setselectedProduct] = useState(products[0])
    const [users, setusers] = useState(100)
    const [VRpremium, setVRpremium] = useState(100*3)
    const [VRenhance, setVRenhance] = useState(100*7)
    const [VPerkspremium, setVPerkspremium] = useState(100*5)
    const [VPerksenhance, setVPerksenhance] = useState(100*9)
    const [steps, setsteps] = useState(100)
    const [showSlider, setshowSlider] = useState(true)
    // const [currentProduct, setcurrentProduct] = useState('Vantage Rewards')
    const [duration, setduration] = useState('monthly')
    // const [width, setwidth] = useState(0)
    


    // Update pricing when user uses the range slider

    const updatePricing = (e) => {
        setusers(e)
        // setwidth((users/5000 * 100)/2)
        e >= 1000 ? setsteps(100) : setsteps(50)
        if(duration === 'yearly' && e <5001){
            setVRpremium((e * 3) * 70/100)
            setVRenhance((e * 7) * 70/100)
        }
        else if(duration === 'monthly' && e < 5001){
            setVRpremium(e * 3)
            setVRenhance(e * 7)
        }
        setVPerkspremium(e * 7)
        setVPerksenhance(e *9)
    }


    // Calculation to move slider thumb and prices when user edits the value directly instead of using the slider

    const changeUsers = (e) => {
        setusers(e.target.value)
        if(duration === 'yearly' && e.target.value < 5001){
            setVRpremium((e.target.value * 3) * 70/100)
            setVRenhance((e.target.value * 7) * 70/100)
        }
        else if (duration === 'monthly' && e.target.value < 5001){
            setVRpremium(e.target.value * 3)
            setVRenhance(e.target.value * 7)
        }
        
        setVPerkspremium(e.target.value * 7)
        setVPerksenhance(e.target.value *9)
    }


    // Calculation to change the prices when users toggle between Yearly and Monthly tabs

    const activeTab = (e) => {
        setduration(e.target.id)
        if(e.target.id === 'yearly' && users < 5001){
            setVRpremium((users * 3) * 70/100)
            setVRenhance((users * 7) * 70/100)
        }
        else if(e.target.id === 'monthly' && users <5001){
            setVRpremium(users * 3)
            setVRenhance(users * 7)
        }
    }
  

    const toggleSlider = (p) => {
       /* p.name === 'Vantage Perks' ? setshowSlider(false) : setshowSlider(true)*/
        if(p.name === 'Vantage Perks' || p.name === 'Vantage Fit'){
            setshowSlider(false)
        }
        else{
            setshowSlider(true)
        }
    }

    return (
        <>
            <section className="container max-w-6xl mx-auto px-6 lg:px-4 pt-10 pb-5 md:py-40 md:pb-0">
                <div className="max-w-sm mx-auto text-center">
                <h2 className="headlines">Choose a Product</h2>
                    {/*<select id="productname" className="pricing-prod-menu">
                        <option value="vrewards">Vantage Rewards</option>
                        <option value="vperks">Vantage Perks</option>
                        <option value="vpulse">Vantage Pulse</option>
                        <option value="vfit">Vantage Fit</option>
                    </select>*/}
                    {/*Designing dropdown for selecting various products*/}


                    <Listbox value={selectedProduct} onChange={setselectedProduct}>
                        <Listbox.Button className = 'w-full mt-5 min-h-auto p-3 uppercase mb-5 border-purple-800 border-2 rounded-sm text-purple-500 font-semibold flex justify-center items-center'>  
                            <img className = 'mx-3' alt= "pic" height = "30px" width = "30px" src = {selectedProduct.logo} /> {selectedProduct.name} <svg className="h-8 w-8 mx-2 text-gray-200 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </Listbox.Button>
                            <Listbox.Options  className = "shadow-lg rounded-lg">
                            {products.map((product) => (
                                <Listbox.Option key={product.id} value={product} disabled={product.unavailable} className = 'flex justify-center items-center'>{({ active, selected }) => (
                                    <h2 onClick = {() => toggleSlider(product)} onKeyDown = {() => toggleSlider(product)} className = {active ? 'bg-purple-50 text-center p-4 w-full cursor-pointer mb-2' : 'cursor-pointer text-center p-4 w-full mb-2'}>{product.name}</h2>
                                )}
                                </Listbox.Option>
                            ))}
                            </Listbox.Options>
                    </Listbox>
                    
                </div>
                

                <h1 className = "headlinetwo">Choose a plan that best <span className= "text-orange">fits</span> your organization</h1>

                {/*Designing the price slider*/}

                <div className ={ showSlider ? "sliderContainer" : "hide"}>
                    <div className = "flex flex-col justify-center w-5/6">
                        <h2 className ='mb-2 mt-3 md:mb-2 md:mt-2 text-xl'>How many users do you expect?</h2>
                        <p className = 'font-semibold md:mb-3 md:mt-3 text-md md:text-md'>More than 5000 users? <span><a href = '#'>Contact Us</a></span></p>
                        <div className = "w-full mb-6 mt-6 md:w-5/6 md:mb-2 md:mt-2">
                            {/* <input className = 'customslider transform -translate-x-4 relative' style = {{width: '100%'}} type = 'range' min = '100' max = '5000' step = {steps} onChange = {updatePricing} value = {users}/> */}
                            <Slider min = {100} max = {5000} step = {steps} onChange = {updatePricing} defaultValue= {users} value = {users} />
                            {/* <div className= "absolute h-3 rounded-lg transform -translate-y-8 -translate-x-1 z-0 w-full"><div className = " rounded-lg h-full bg-purple-500" style = {{ width : `${width}%` }}></div></div> */}
                        </div>
                        {/* <Slider progress defaultValue={users} value = {users} onChange={updatePricing} step = {steps} min = {0} max = {5000} /> */}
                        {/* <RangeSlider className = 'w-full mb-6 mt-6 md:w-5/6 md:mb-2 md:mt-2 customslider transform -translate-x-4'  defaultValue={[10, 50]} /> */}
                        <div className = 'flex justify-between w-full md:w-5/6 font-semibold mt-2'>
                            <p className = 'text-sm'>100 users</p>
                            <p className = 'text-sm pr-2'>5000 users</p>
                           
                        </div>
                        <p className = 'mt-1 mb-8 md:mt-2 mb-0 text-sm pr-2'>Move the slider to estimate how many people you expect to participate.</p>
                    </div>

                    <div className = "flex justify-center items-center rounded-lg shadow-lg w-1/2 md:w-1/6 bg-purple-500">
                        <div className= "flex flex-col justify-center items-center w-full h-full p-3 rounded-lg font-semibold text-4xl border-2 border-purple-800">
                            <input className = 'font-bold text-3xl pt-2 pb-2 text-center text-purple-500' type = "text" value = {users} onChange = {changeUsers}/>
                            <p className = "text-xl font-medium transform translate-y-2 text-white">Users</p>
                        </div>
                    </div>
                   
                </div>
                
                <div className="container pricing-layout mx-auto">
                    <div id="vrewards" className={selectedProduct.name === 'Vantage Rewards' ? 'data flex z-50' : 'data hidden z-0'}>
                        <VantageRewards VRenhance = {VRenhance} VRpremium = {VRpremium} activeTab = {activeTab} duration = {duration}/>
                    </div>
                    <div id="vperks" className={selectedProduct.name === 'Vantage Perks' ? 'data flex z-50' : 'data hidden z-0'}>
                        <VantagePerks VPerksenhance = {VPerksenhance} VPerkspremium = {VPerkspremium} />
                    </div>
                    <div id="vpulse" className={selectedProduct.name === 'Vantage Pulse' ? 'data flex z-50' : 'data hidden z-0'}>
                        <VantagePulse VPerkspremium = {VPerkspremium} />
                    </div>
                    <div id="vfit" className={selectedProduct.name === 'Vantage Fit' ? 'data flex z-50' : 'data hidden z-0'}>
                        <VantageFit />
                    </div>
                </div>
            </section>

            <div id= "awards"></div>
            
            <section className = 'flex flex-col w-full justify-center items-center p-2 mt-0 md:mt-16'>
                <h1 className = "font-bold transform scale-90 text-3xl md:text-4xl text-center text-gray-900">Got some questions in mind?</h1>
                <h1 className= 'text-3xl md:text-4xl font-bold transform scale-90 text-center text-gray-900'>Read out <span className = "text-orange">FAQs</span> to find out the answers</h1>
                <div className = 'w-full md:w-5/6 xl:w-1/2 flex justify-center items-center mt-10 md:mt-12'><Faqs /></div>
            </section>
            

            <section id="schedule-demo" className="container px-6 py-10 md:py-20 lg:pb-40">
                <div className="text-center">
                    <h2 className="section-title">
                        We would love to hear from you!
                    </h2>
                    <p className="section-subtitle">Kindly share the following information for us to serve you better.</p>
                </div>
                <div className="max-w-xl mx-auto mt-3 md:mt-10">
                    <div className="card-1 w-full mb-1 text-gray-900 rounded shadow-lg p-8 lg:p-10 py-10 lg:py-14">
                        <div className="form-section">
                            <HubspotForm
                                portalId='6153052'
                                formId='d7303dc2-49a8-4385-a292-e7dca3f454e2'
                                onSubmit={() => console.log('Submit!')}
                                onReady={(form) => console.log('Form ready!')}
                                loading={<div>Loading...</div>}
                            />
                            {/* <div className="notetext text-center font-normal text-sm">We safeguard your personal information in accordance with our <a href="/privacy-policy/">Privacy Policy</a></div> */}
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

const renderPricingEn=()=>{
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#pricing-en")
        if (id != null){
            ReactDOM.render(
                <PricingEn />,
                id
            )
        }
    }
}

setTimeout(renderPricingEn,100)

export default PricingEn
