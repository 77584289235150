import { Link } from "gatsby"
import React from "react"

const VantagePulse = ({ VPerkspremium }) => (
  <section className="pulseContainer">
    <div className="flexItemJustifyCenter my-10 md:my-15 h-auto min-h-5rem w-full">
      <div className="p-5 px-0 md:w-4/6 md:p-10 w-full h-full justify-center flex flex-col rounded-lg shadow-lg">
        <div className="px-6 py-4">
          <h1 className="pulsePackageDeal">Package Deal</h1>
          <div className="flex items-center h-auto">
            <h1 className="premiumPriceValue">{VPerkspremium}</h1>{" "}
            <p className="premiumDuration">&nbsp; / Per month</p>
          </div>
          <p className="pulseRate">$7 per user per month</p>
          <p className="text-lg font-medium text-gray-800">
            One-time Implementation cost applicable
          </p>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-full px-6">
            <Link to="/request-demo/" className="vc-colored-btn">
              Choose Package
            </Link>
          </div>
        </div>
        <div className="mt-4 w-full text-center">
          <p className="text-purple-900">
            <a href="#schedule-demo">Schedule a Demo</a>
          </p>
        </div>
        <div className="flexJustifyCenter -mb-8">
          <div className="p-0 w-full mb-8 mt-4">
            <div className="h-full p-6 flex flex-col relative overflow-hidden">
              <p className="flexItemCenter text-gray-500 mb-4">
                <span className="pulseBulletPoints">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    strokewidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Unlimited surveys & questions
              </p>
              <p className="flexItemCenter text-gray-500 mb-4">
                <span className="pulseBulletPoints">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    strokewidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Quizes with customer feedback
              </p>
              <p className="flexItemCenter text-gray-500 mb-4">
                <span className="pulseBulletPoints">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    strokewidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Advance Logic
              </p>
              <p className="flexItemCenter text-gray-500 mb-4">
                <span className="pulseBulletPoints">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    strokewidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Custom Reports
              </p>
              <p className="fflexItemCenter text-gray-500 mb-4">
                <span className="pulseBulletPoints">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    strokewidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Priority Support
              </p>
              <p className="flexItemCenter text-gray-500 mb-4">
                <span className="pulseBulletPoints">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    strokewidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                White Label Solution
              </p>
              <p className="flexItemCenter text-gray-500 mb-4">
                <span className="pulseBulletPoints">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    strokewidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                SSO Integration
              </p>
              <p className="flexItemCenter text-gray-500 mb-4">
                <span className="pulseBulletPoints">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    strokewidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Dedicated Account Manager
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default VantagePulse
