import React from "react";
import ReactDOM from "react-dom";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup} from 'pure-react-carousel'

const TestimonialsFr = () => {
    return (
    <div>
    <div className="section-label">TÉMOIGNAGES</div>
    <h2 className="section-title mb-14 md:mb-20">
        Nos clients nous aiment autant que nous les aimons.<br/> Voir par vous-même!
    </h2>
    <CarouselProvider className="max-w-4xl relative mx-auto"
        naturalSlideWidth={100}
        naturalSlideHeight={55}
        totalSlides={11}
    >
        <div className="quote-icon1 relative">
            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/05/quotation-mark.svg" alt="quote-icon" />
        </div>
        <Slider className="rounded-lg bg-purple-50">
            <Slide index={0}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle">
                        Nous sommes très heureux d'être associés à InfyGold+, une partie du programme de récompenses et de reconnaissance d'Infosys, et nous gérons ce programme avec Vantage Circle. C'est formidable d'avoir travaillé avec Vantage Circle et l'équipe nous a aidé à offrir des expériences formidables à nos employés via une plateforme unique, qui est facile à utiliser, offre une gamme d'options et, surtout, est disponible sur les smartphones personnels via l'application.
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Shruthi Bopaiah</div>
                        <div className="mb-5">  VP Assistant et Responsable Communication Interne <strong>Infosys</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/v1624509292/gatsbycms/uploads/2021/06/infosys-testomony.png" alt="Infosys Logo" width="120" height="60"/>
                    </div>
                </div>
            </Slide>
            <Slide index={1}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle">
                        Vantage Rewards est la première plateforme de récompenses et de reconnaissance en ligne que nous avons mise en place. Le système de récompense par points a rendu le suivi des récompenses et ses données plus facile à gérer. La formule de rapport a été utile pour comprendre le budget et les allocations de récompenses. Cela nous a également donné la possibilité d'analyser où nous en sommes en termes de reconnaissance de nos employés. Chaque organisation, comme la nôtre, gagnerait certainement à utiliser la plateforme.
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Aiman Fatima </div>
                        <div className="mb-5">Manager – Employee Relations, <strong>Merilytics</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/06/merilytics-testomony.png" alt="Merilytics Logo" width="120" height="41"/>
                    </div>
                </div>
            </Slide>
            <Slide index={2}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle">
                        Vantage Rewards fait partie de nos efforts renouvelés pour établir une culture de reconnaissance en donnant à nos employés la possibilité de choisir leurs propres récompenses. La fonction de reconnaissance instantanée nous permet de récompenser immédiatement le bon travail. De l'impatience d'être incité, d'avoir le choix entre plusieurs options de récompense et l'interface conviviale ont fait que nos employés sont plus motivés que jamais.
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Roshan Andrews</div>
                        <div className="mb-5">RH - Expérience Employé & Communication Interne, <strong>Arvind</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/06/arvind-testomony.png" alt="Arvind Logo" width="120" height="41"/>
                    </div>
                </div>
            </Slide>
            <Slide index={3}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle">
                        Vantage Rewards fait partie de nos efforts renouvelés pour établir une culture de reconnaissance en donnant à nos employés la possibilité de choisir leurs propres récompenses. La fonction de reconnaissance instantanée nous permet de récompenser immédiatement le bon travail. De l'impatience d'être incité, d'avoir le choix entre plusieurs options de récompense et l'interface conviviale ont fait que nos employés sont plus motivés que jamais.  
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Nikita Kamtekar</div>
                        <div className="mb-5">Responsable Communication Interne, <strong>TATA Realty</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/06/tatarealty-testomony.png" alt="TATA Realty Logo" width="120" height="49"/>
                    </div>
                </div>
            </Slide>
            <Slide index={4}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle">
                        Nous apprécions vraiment la façon dont Vantage Circle a créé une vague d'esprit de famille au sein d'Adecco et la façon dont la plateforme sert à tous nos collègues dans toutes les branches et rassemble tout le monde. Les points de récompense sont donnés à nos employés et ils sont libres de choisir où ils veulent dépenser leurs points, ce qui est une grande victoire pour nous. Vantage Circle a fait un excellent travail pour Adecco et je le recommanderais sans hésiter à d'autres entreprises.
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Bani Sodhi</div>
                        <div className="mb-5">Ressources Humaines, <strong>The Adecco Group</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/06/adecco-testomony.png" alt="The Adecco Group Logo" width="120" height="57"/>
                    </div>
                </div>
            </Slide>
            <Slide index={5}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle">
                        Wipro Advantage (propulsé par Vantage Circle) a été lancé l'année dernière pour offrir des privilèges exclusifs à nos employés en Inde. Les réactions des employés ont été très positives et nous pensons que c'est une proposition de valeur exceptionnelle pour nos employés.
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Samir Gadgil</div>
                        <div className="mb-5">Vice-président et chef des RH, <strong>Wipro BPS</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/v1624509293/gatsbycms/uploads/2021/06/wipro-testomony.png" alt="Wipro BPS Logo" width="120" height="74"/>
                    </div>
                </div>
            </Slide>
            <Slide index={6}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle">
                        Vantage Circle a transformé notre précédent processus de récompense manuel. Grâce à sa facilité d'utilisation, à la rationalisation de la distribution des récompenses et à la flexibilité du choix des récompenses, notre délai d'exécution a considérablement diminué. Le tableau de bord d'administration de Vantage Circle nous donne des informations incroyables sur les modèles d'engagement basés sur des données. Nous avons constaté une réaction en série d'amélioration des performances professionnelles dans toutes nos équipes. C'est vraiment la solution parfaite pour des récompenses et une reconnaissance sans tracas.
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Ritesh Joseph</div>
                        <div className="mb-5">Partenaire d'affaires RH, <strong>iSchoolConnect</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/06/ischoolconnect-testomony.png" alt="iSchoolConnect Logo" width="140" height="15"/>
                    </div>
                </div>
            </Slide>
            <Slide index={7}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle">
                        Cela fait un peu plus d'un an que nous avons lancé notre plateforme de récompenses et de reconnaissance en partenariat avec Vantage circle. FirstReward est une bonne plateforme en ligne qui nous permet de récompenser les employés à travers le monde, d'encourager l'appréciation ascendante, descendante et entre pairs. En outre, l'outil nous a également permis de développer et de renforcer notre culture existante de récompenses et d'appréciation. L'équipe s'est associée à nous à chaque étape pour apporter des améliorations lorsque cela était possible.
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="mb-5"><strong>Firstsource Solutions Limited</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/06/firstsource-testomony.png" alt="Firstsource Logo" width="120" height="41"/>
                    </div>
                </div>
            </Slide>
            <Slide index={8}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle">
                        Vantage Circle a rendu notre processus de récompense et de reconnaissance transparent, et nous sommes désormais habilités à reconnaître et à célébrer instantanément l'excellent travail. Remettre aux employés des récompenses monétaires ou reconnaître d'autres réalisations internes n'a jamais été aussi facile. La récompense en points donne aux employés la possibilité de choisir leurs cartes-cadeaux parmi différents magasins en ligne/de détail. La récompense en points donne aux employés la possibilité de choisir leurs cartes-cadeaux parmi différents magasins en ligne/de détail. Cela encourage la motivation des employés en présentant leurs réalisations d'étapes et de reconnaissances via le classement social sur la plate-forme. Cela nous permet également de renforcer la satisfaction des employés à long terme et d'instaurer une culture dans laquelle la reconnaissance ne se fait jamais attendre.
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Pankaj Gupta</div>
                        <div className="mb-5">Associé-Gérant, <strong>TA Digital</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/06/TA-Digital-testimony.png" alt="TA Digital Logo" width="140" height="30"/>
                    </div>
                </div>
            </Slide>
            <Slide index={9}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle">
                        J'ai découvert la plate-forme Vantage Circle grâce à divers collègues qui travaillent avec différentes entreprises. La plate-forme Vantage Rewards nous a aidé à acheminer nos récompenses et notre reconnaissance (R&R) et a également aidé à suivre nos allocations budgétaires R&R. La principale caractéristique de VC est la variante de conversion des points en argent, c'est-à-dire 1 point = 1 roupie. De plus, les points de gestion se déroulent vraiment bien et nous voulions simplement que le portail intègre ce processus de nomination. Je recommanderai certainement la plateforme Vantage Rewards à d'autres organisations.
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Shiney Xavier</div>
                        <div className="mb-5">Associé-Gérant RH, <strong>VFI SLK</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/06/VFI-SLK-testimony.png" alt="VFI SLK Logo" width="140" height="42"/>
                    </div>
                </div>
            </Slide>
            <Slide index={10}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle">
                        Vantage Circle nous a aidé à réorganiser entièrement notre processus de récompenses et nous a donné la liberté de choisir parmi les diverses options disponibles en ligne en fonction des besoins spécifiques de chacun. La plate-forme en ligne a contribué à remonter le moral des employés dans le cadre de la famille Escalon, même pendant COVID-19. Cela n'aurait pas été possible sans une telle plateforme lorsque les employés étaient dispersés dans tout le pays. Elle nous a beaucoup aidés et a été notre option préférée pour toutes nos activités de motivation des employés. Nous sommes heureux d'être associés à Vantage Circle et nous nous réjouissons d'un partenariat à long terme avec eux.
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Swati Kohli</div>
                        <div className="mb-5">Chef des RH, <strong>Escalon Business Services</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/06/Escalon-testimony.png" alt="Escalon Business Services Logo" width="140" height="37"/>
                    </div>
                </div>
            </Slide>
        </Slider>
        <ButtonBack className="shadow eb-carousel-btn">
            <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
        </ButtonBack>
        <ButtonNext className="shadow eb-carousel-btn">
            <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>
        </ButtonNext>
        <DotGroup />
    </CarouselProvider>
</div>
    )
};


const renderTestimonials=()=>{
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#testimonials-fr")
        if (id != null){
            ReactDOM.render(
                <TestimonialsFr />,
                id
            )
        }
    }
}

setTimeout(renderTestimonials,100)

export default TestimonialsFr;