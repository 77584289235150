import React from "react";
import ReactDOM from "react-dom";
// import { Trans } from 'gatsby-plugin-react-i18next';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup} from 'pure-react-carousel'
import Trans from '../../components/Trans';

const TestimonialsBrandsFr = () => { 
    return (
        <div>
            <section className="testimonial-slider container text-center px-6 py-40">
            <h2 className="mb-10 md:mb-20 section-title">
             Témoignages de nos partenaires de marque
            </h2>
            <CarouselProvider className="max-w-4xl relative mx-auto"
                naturalSlideWidth={100}
                naturalSlideHeight={60}
                totalSlides={3}
            >
            <div className="quote-icon1 relative">
                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/05/quotation-mark.svg" alt="quote-icon" />
            </div>
            <Slider className="rounded-lg bg-purple-50">
                    <Slide index={0}>
                        <div className="mx-auto p-6 md:p-20">
                            <div className="client-img mb-10">
                                <img className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/vantagecms/uploads/2020/07/akshay-mehrotra.png" alt="Akshay Mehrotra"/>
                            </div>
                            <p className="mb-10 section-subtitle">
                                Chez EarlySalary, nous cherchons à aider les jeunes professionnels salariés chaque fois qu'ils manquent d'argent. Notre partenariat avec Vantage Circle nous a permis de réaliser 100 milles prêts et est l'un des meilleurs partenaires pour EarlySalary. Vantage Circle nous a aidés à atteindre et à interagir avec un large public d'entreprises.
                            </p>
                            <div className="client-description">
                                <div className="font-bold text-lg">Akshay Mehrotra</div>
                                <div className="mb-5">Co-Founder & CEO, <b>EarlySalary.com</b></div>
                            </div>
                            <div className="testimonial-company-logo">
                                <img className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/vantagecms/uploads/2020/07/ad_earlysalary.png" alt="Early Salary"/>
                            </div>
                        </div>
                    </Slide>
                    <Slide index={1}>
                        <div className="mx-auto p-6 md:p-20">
                            <div className="client-img mb-10">
                                <img className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/uploads/2019/11/default-people.png" alt="Profile"/>
                            </div>
                            <p className="mb-10 section-subtitle">
                                Vantage Circle nous a aidés à choisir et à personnaliser des offres qui nous ont aidés à développer notre activité en ciblant un réseau de confiance des employés d'entreprise.
                            </p>
                            <div className="client-description">
                                <div className="font-bold text-lg">Rohit Malhotra</div>
                                <div className="mb-5">Responsable régional, <b>Samsung Mobiles</b></div>
                            </div>
                            <div className="testimonial-company-logo">
                                <img className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/vantagecms/uploads/2020/07/ad_samsung.png" alt="Samsung"/>
                            </div>
                        </div>
                    </Slide>
                    <Slide index={2}>
                        <div className="mx-auto p-6 md:p-20">
                            <div className="client-img mb-10">
                                <img className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/uploads/2019/11/default-people.png" alt="Profile"/>
                            </div>
                            <p className="mb-10 section-subtitle">
                                Vantage Circle nous a permis d'atteindre notre public idéal, de renforcer la notoriété de la marque et de générer des ventes régulières chaque mois.
                            </p>
                            <div className="client-description">
                                <div className="font-bold text-lg">Amruta Gupta</div>
                                <div className="mb-5">Responsable nationale de la marque, <b>Pepperfry</b></div>
                            </div>
                            <div className="testimonial-company-logo">
                                <img className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/vantagecms/uploads/2020/07/ad_pepperfry.png" alt="Samsung"/>
                            </div>
                        </div>
                    </Slide>
                    {/* <Slide index={2}>
                        <div className="mx-auto p-6 md:p-20">
                            <div className="client-img mb-10">
                                <img className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/uploads/2019/11/default-people.png" alt="Profile"/>
                            </div>
                            <p className="mb-10 section-subtitle">
                                <Trans>Vantage Circle allowed us to reach out to our ideal audience and build brand awareness and generate consistent sales every month.</Trans>
                            </p>
                            <div className="client-description">
                                <div className="font-bold text-lg">Amruta Gupta</div>
                                <div className="mb-5"><Trans></Trans>National Brand Head, <b>Pepperfry</b></div>
                            </div>
                            <div className="testimonial-company-logo">
                                <img className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/vantagecms/uploads/2020/07/ad_pepperfry.png" alt="Pepperfry"/>
                            </div>
                        </div>
                    </Slide> */}
                </Slider>
                <ButtonBack className="shadow eb-carousel-btn">
                    <svg className="mx-auto text-purple-200" fill="currentColor" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
                </ButtonBack>
                <ButtonNext className="shadow eb-carousel-btn">
                    <svg className="mx-auto text-purple-200" fill="currentColor" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>
                </ButtonNext>
                <DotGroup />
                </CarouselProvider>
            </section>
        </div>
    )
}


const renderTestimonials=()=>{
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#testimonials-brands-fr")
        if (id != null){
            ReactDOM.render(
                <TestimonialsBrandsFr/>,
                id
            )
        }
    }
}

setTimeout(renderTestimonials,100)

export default TestimonialsBrandsFr;

