import React, {useRef, useEffect} from 'react'
import {useElementOnScreen} from '../hooks/useElementOnScreen'
import ReactDOM from 'react-dom'
import '../styles/PercentageCircle.css'

export const animateValue = (div, start, end, duration) => {
    const obj = div.current
    let startTimestamp = null;
    const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        obj.innerHTML = Math.floor(progress * (end - start) + start) + "%";
        if (progress < 1) {
            window.requestAnimationFrame(step);
        }
    };
    window.requestAnimationFrame(step);
}

function PercentageCircle({percentage, color, size, title}) {
    const counterRef = useRef()

        const [containerRef, isVisible] = useElementOnScreen({
            root: null,
            threshold: 0,
            rootMargin: "0px"
        })

    useEffect(() => {
        animateValue(counterRef, 0, percentage, 2000)
    }, [isVisible])
   
    return (
        <>
            <div ref={containerRef} className="percentage-circle-wrapper">
                
                <div className="single-chart" style={{'width': `${size}px`}}>
                    <svg viewBox="0 0 36 36" className="circular-chart" >
                        <path className="circle-bg"
                            d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <path className={`circle ${isVisible ? "circle-animate" : "circle-animate-stop"}`}
                            style={{'stroke': `${color}`}}
                            strokeDasharray={`${percentage}, 100`}
                            d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <text x="18" y="20.35" ref={counterRef} className="percentage counter" data-target={`${percentage}`}>0%</text>
                    </svg>
                </div>
                <div className="percentage-circle-title">
                    <p>{title}</p>
                </div>
            </div> 
        </>
    )
}


const renderComponent=()=>{
    if(typeof window !== "undefined"){ 
        let id1 = document.querySelector("#percentCircle1")
        if (id1 != null){
            ReactDOM.render(
                <PercentageCircle percentage={93} color={`#654AB7`} size={100} />,
                id1
            )
        } 
        let id2 = document.querySelector("#percentCircle2")
        if (id2 != null && typeof window !== "undefined"){
            ReactDOM.render(
                <PercentageCircle percentage={94} color={`#FF9041`} size={100} />,
                id2
            )
        }
        let id3 = document.querySelector("#percentCircle3")
        if (id3 != null && typeof window !== "undefined"){
            ReactDOM.render(
                <PercentageCircle percentage={95} color={`#654AB7`} size={100} />,
                id3
            )
        }
        let id4 = document.querySelector("#percentCircle4")
        if (id4 != null && typeof window !== "undefined"){
            ReactDOM.render(
                <PercentageCircle percentage={97} color={`#FF9041`} size={100} />,
                id4
            )
        }
        let id5 = document.querySelector("#percentCircle5")
        if (id5 != null && typeof window !== "undefined"){
            ReactDOM.render(
                <PercentageCircle percentage={93} color={`#654AB7`} size={100} />,
                id5
            )
        }
    }
}

setInterval(renderComponent,100)
export default PercentageCircle