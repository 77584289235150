import React from "react";
import ReactDOM from "react-dom";

const AwardMentions = () => (
    <>
        <div className="max-w-3xl mt-5 sm:mt-8 grid gap-5 grid-cols-3 md:grid-cols-6 justify-items-center awardsimg mx-auto">
            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1642763057/gatsbycms/uploads/2022/01/winter-award-1.png" alt="G2 award1" width="110" height="125"/>
            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1642763057/gatsbycms/uploads/2022/01/winter-award-3.png" alt="G2 award2" width="110" height="125"/>
            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1642763057/gatsbycms/uploads/2022/01/winter-award-4.png" alt="G2 award3" width="110" height="125"/>
            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1642763057/gatsbycms/uploads/2022/01/winter-award-5.png" alt="G2 award4" width="110" height="125"/>
            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1642763057/gatsbycms/uploads/2022/01/winter-award-6.png" alt="G2 award5" width="110" height="125"/>
            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/h_124/gatsbycms/uploads/2021/08/GPTW_Certified_AUG_2021_-_AUG_2022_LOWRES.png" alt="Great Place to Work" width="73" height="124" />
        </div>
        <div className="max-w-2xl pl-5 mt-5 sm:mt-8 grid gap-5 grid-cols-3 md:grid-cols-5 justify-items-center awardsimg mx-auto">
            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1629548690/gatsbycms/uploads/2021/12/crozdesk-employee-engagement-software-leader-badge.png" alt="Crozdesk award1" width="100" height="110" />
            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1629548691/gatsbycms/uploads/2021/08/crozdesk-2.png" alt="Crozdesk award2" width="110" height="110" />
            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1629548691/gatsbycms/uploads/2021/08/crozdesk-3.png" alt="Crozdesk award3" width="110" height="110" />
            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1629548691/gatsbycms/uploads/2021/08/crozdesk-4.png" alt="Crozdesk award4" width="110" height="110" />
            <a href="https://www.softwarereviews.com/" target="_blank" rel="nofollow noopener"><img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1628664738/gatsbycms/uploads/2021/08/Software-reviews-_resized.png" alt="Software Reviews award" width="110" height="100" /></a>
        </div>
    </>
);

const renderAwards=()=>{
    if(typeof window !== "undefined"){ 
        let id1 = document.querySelector("#awards")
        if (id1 != null){
            ReactDOM.render(
                <AwardMentions />,
                id1
            )
        } 
    }
}
setTimeout(renderAwards,100);
export default AwardMentions;