import React from "react"
import { Link } from "gatsby"
// import Helmet from "react-helmet";
// import { useState } from "react";

const VantageRewardsEs = ({ VRpremium, VRenhance, activeTab, duration }) => {
  return (
    <section className="rewardsContainer">
      {/*<div className="text-center py-20 text-lg font-bold">Pricing Slider</div>*/}

      {/* Designing monthly and yearly tabs */}
      <div className="monthlyYearlyTabContainer">
        <div className="tabContainer">
          <button
            onClick={activeTab}
            id="monthly"
            className="z-10 text-sm text-white font-semibold py-3 px-8 text-white focus:outline-none"
          >
            Pay Monthly
          </button>
          <button
            onClick={activeTab}
            id="yearly"
            className="z-10 py-3 px-8 text-sm text-white font-semibold focus:outline-none"
          >
            Pay Yearly
          </button>
          <div
            className={
              duration === "yearly"
                ? "absolute left-1 bottom-1/6 w-1/2 bg-white rounded-full z-20 h-5/6 activeTab flex justify-center items-center"
                : "flex justify-center items-center absolute left-1 bottom-1/6 w-1/2 bg-white inactiveTab rounded-full z-20 h-5/6"
            }
          >
            <h4 className="capitalize text-sm font-bold">Pay {duration}</h4>
          </div>
        </div>
        <p className="text-sm mt-6 text-gray-900">
          Choose yearly plan and save{" "}
          <span className="text-orange font-semibold">30% off</span>
        </p>
      </div>

      {/*Designing the price cards*/}
      {/*Premium*/}
      <div className="flexJustifyCenter">
        <div className="premiumParent">
          <div className="premiumCard">
            <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
              Premium
            </h2>
            <div className="flex items-center h-auto">
              <h3 className="premiumPriceValue">${VRpremium}</h3>{" "}
              <p className="premiumDuration">&nbsp; / Per month</p>
            </div>
            <p className="premiumRate">$3 per user per month</p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="premiumbulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Recompensa automatizada
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="premiumbulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Reconocimiento social
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="premiumbulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Candidaturas a colegas
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="premiumbulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Funciones de administración avanzadas
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="premiumbulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Flujo de Trabajo de Adjudicaciones
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="premiumbulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Configuración de insignias y premios
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="premiumbulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Material de lanzamiento
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="premiumbulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Panel de Analytics
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="premiumbulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Recompensas basadas en puntos
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="premiumbulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Redención de tarjeta regalo
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="premiumbulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Gestión global de puntos
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="premiumbulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Carga masiva mediante CSV
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="premiumbulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Catálogo global
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="premiumbulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Informes personalizados
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="premiumbulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              Asistencia prioritaria
            </p>
            <div className="mt-5 mb-4 sm:mt-8 md:mt-10 flex justify-center">
              <div>
                <Link
                  to="#schedule-demo"
                  className="vc-colored-btn text-sm md:text-base"
                >
                  Schedule a Free Demo
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/*Enhanced*/}
        <div className="p-0 md:p-2 mt-6 md:mt-0 md:w-1/2 w-full">
          <div className="enhancedCard">
            <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
              Emprendedora
            </h2>
            <div className="flex items-center h-auto">
              <h2 className="enhancedPriceValue">${VRenhance}</h2>{" "}
              <p className="enhancedDuration">&nbsp; / Per month</p>
            </div>
            <p className="text-purple-10">$7 per user per month</p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="enhancedBulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              <p className="text-purple-50 mb-0">
                Incluye todo en Premium, además:
              </p>
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="enhancedBulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              <p className="text-purple-50 mb-0">Servicio Anuraio</p>
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="enhancedBulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              <p className="text-purple-50 mb-0">
                Flujo de trabajo personalizado
              </p>
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="enhancedBulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              <p className="text-purple-50 mb-0">Solución White Label</p>
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="enhancedBulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              <p className="text-purple-50 mb-0">Integración de SSO</p>
            </p>
            <p className="flexItemCenter text-gray-500 mb-4">
              <span className="enhancedBulletPoint">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              <p className="text-purple-50 mb-0">
                Administrador de cuentas dedicado
              </p>
            </p>
            <div className="mt-5 sm:mt-8 md:mt-10 flex justify-center">
              <div>
                <Link
                  to="#schedule-demo"
                  className="vc-white-btn text-sm md:text-base"
                >
                  Schedule a Free Demo
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VantageRewardsEs
