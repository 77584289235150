import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';


// const data = {
//     rows: [
//         {
//             title: "How can I apply for any job profiles in Vantage Circle?",
//             content: <div><p>Thanks for asking, to apply with us, you need to visit our careers page for relevant profiles. If anyone of the listed job profile is of your interest or relevant to your skills and experience, you can apply by clicking on the <strong>APPLY FOR THIS JOB</strong> button after going through the Job Description.</p>
//             <p>Upon clicking on the <strong>APPLY FOR THIS JOB</strong> button, you will be redirected to our career portal. You will have to fill out the application form with your details and attach the resume or cover letter.</p></div>,
//         },
//         {
//             title: "Can I apply for more than one job?",
//             content:
//                 <p >Yes, you can apply for more than one job but we would advise you to apply to a similar or relevant job profile.</p>,
//         },
//         {
//             title: "Why can’t I apply for the same job profile twice?",
//             content: <div> <p>You cannot apply twice for the same profile using the same email address and phone number. However, if you had an issue with the first application, you can apply again but with a different email address and phone number.</p>
//             <p>We do suggest that you apply once and recheck your application before submitting it to ensure a smooth process.</p></div>,
//         },
//         {
//             title: "What happens after I submit my application? What is the package version",
//             content: <div> <p>After you submit your application, the recruiting team will be checking the resumes, and based on the relevance with the job role, your application will be processed.</p>
//             <p className="mb-0">If shortlisted, you will receive an email from us to continue for further process.</p></div>,
//         },
//         {
//             title: "How much time does it take to process the applications?",
//             content:  <p>Our recruiters are trying their best to ensure a smooth and fast process. However, due to the large volume of applications and multiple openings in our organization, it might take up to 30 to 45 days to process the application.</p>
//         },
//         {
//             title: "How will I know if my application is shortlisted or rejected?",
//             content: 
//                 <div>
//                     <p>Once you submit your application, the process of shortlisting is started. The candidates who are shortlisted will receive a call or an email from our end. You will be given certain tasks or called for an interview based on which you will be assessed for further process.</p>
//                     <p>If your application is rejected, you will be receiving an email from our career portal <strong>no-reply@email.keka-mail.com</strong> mentioning the same.</p>
//                     <p className="mb-0">However, sometimes our mails can be forwarded to the <strong>Spam</strong> section, so kindly check your emails regularly after you submit your application.</p>
//                 </div>
//         },
//         {
//             title:'Is remote working available in Vantage Circle?',
//             content: 
//             <p className="mb-0">Currently, due to the COVID scenario in the country, our employees are working remotely. However, we don’t offer permanent remote working.</p>
//         },
//         {
//             title: 'How will I know the reason for rejecting my application?',
//             content:
//             <div>
//                 <p>Glad, you asked that. The applications are rejected based on the criterias that are set for the candidate we are looking for. </p>
//                 <p>Your application can be rejected if your profile is not relevant to the job description, or you don't meet the given criterias required for the profile.</p>
//                 <p className="mb-0">Also, the applications are rejected if you submit an incomplete profile or did not submit the resume.</p>
//             </div>
//         },
//         {
//             title: 'Can I refer someone?',
//             content :
//             <div>
//                 <p>Yes we do welcome referrals. We would suggest that the referred candidate himself or herself will have to apply from their own email id and contact number with the resume on our career portal.</p>
//                 <p>Also, the candidate can mention the name of the individual who has referred him/her to apply in Vantage Circle, while filling the application form.</p>
//                 <p className="mb-0">Referral can also be any employee of Vantage Circle with whom you are acquainted with.</p>
//             </div>
//         },
//         {
//             title: 'How will I be updated about the jobs in Vantage Circle?',
//             content :
//             <div>
//                 <p>To stay updated about jobs in our organization, you need to check our <a href="/careers/">Careers</a> page regularly. You can also stay updated about new profiles by following our <a href="https://www.linkedin.com/company/vantage-circle/">LinkedIn</a> , <a href="https://www.instagram.com/vantagecircle/">Instagram</a> or <a href="https://www.facebook.com/vantagecircle">Facebook</a> page.</p>
//                 <p className="mb-0">Also, you can check other job portals like Assam Careers, sakori.org, Naukri, Yuvajobs etc.</p>
//             </div>
//         }
        
//     ],
// };


// const styles = {
//     // bgColor: 'white',
//     titleTextColor: "#654AB7",
//     rowTitleColor: "#313f64",
//     // rowContentColor: 'grey',
//     arrowColor: "#654AB7",
// }

// const config = {
//     animate: true,
//     arrowIcon: <svg class="h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>,
//     tabFocus: true,
// };


// const Faqs = () => {
//     return (
//         <div>
//               <Faq
//                 data={data}
//                 styles={styles}
//                 config={config}
//             />
//         </div>
//     )
// }

const FaqsEs = () => {
    return(
                <div className="career-faq bg-purple-50 p-5 md:p-14">
                   <Accordion preExpanded={['a']}>
                        <AccordionItem uuid="a">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                1.&nbsp;¿Caducan sus puntos?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>No, no necesitas preocuparte por la caducidad del punto.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    2.&nbsp;¿Cuál es el costo de suscripción?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p className="mb-0">El costo de la suscripción depende del módulo y los planes que elija.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    3.&nbsp;¿Cómo seleccionamos el mejor plan para nuestra empresa?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>El mejor plan podría ser elegido dependiendo del tamaño y los requisitos del empleado.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    4.&nbsp;¿De qué depende el costo de suscripción?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>El coste de la suscripción se calcula en función de dos costes: un coste único de configuración y un cargo mensual.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    5.&nbsp;¿Podemos optar por un juicio?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p className="mb-0">Sí, definitivamente puedes optar por un juicio</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    6.&nbsp;¿Tengo que pagar extra por la integración a mi actual HRIS? 
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>No, incluiremos su HRIS existente en la configuración y estará cubierto por los cargos de configuración única.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    7.&nbsp; ¿Tengo que pagar extra por el Anuario de Servicio?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p className="mb-0">Sí, Service Yearbook forma parte de la versión premium de Vantage Rewards.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    8.&nbsp;Como organización, ¿podemos elegir la duración de la facturación y el pago?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>Sí, la facturación y los pagos podrían clasificarse en términos trimestrales, semestrales o anuales.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    9.&nbsp;¿Puedo optar por una cesta completa de todas las herramientas?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>Tenemos un paquete completo con Vantage Perks, Vantage Rewards, Vantage Fit y Vantage Pulse. Para saber más por favor contáctenos aquí.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion> 
              </div>
    )
}

export default FaqsEs
