import React, { useEffect, useState } from 'react'
import HubspotForm from 'react-hubspot-form'

const BottomBanner = () => {
    const [showBanner, setshowBanner] = useState(false)

        const handleBanner = () => {
        let bannerVisible = JSON.parse(window.sessionStorage.getItem("bannerState"));
        if(window.scrollY > 2000 && bannerVisible) setshowBanner(true)
        // console.log('bannervisible', bannerVisible, 'window.scrollY', window.scrollY > 2000)
    }
    const closeBanner = () => {
      setshowBanner(false)
      JSON.stringify(window.sessionStorage.setItem("bannerState", false ))
      window.removeEventListener("scroll", handleBanner)
    }
  
    useEffect(() => {
      let bannerCheck = JSON.parse(window.sessionStorage.getItem("bannerState"))
      if(bannerCheck === null) JSON.stringify(window.sessionStorage.setItem("bannerState", true ))
      // else return
      window.addEventListener("scroll", handleBanner)
      return () => {
        window.removeEventListener("scroll", handleBanner)
      }
    }, []) 

  return (
    // <div className={showBanner ? `slide-in-banner-container bg-indigo-100 shadow-darkgray border-md showBanner transform scale-75 origin-bottom-left` : `slide-in-banner-container bg-indigo-100 shadow-darkgray border-md transform scale-75 origin-bottom-left`}>
    // <div className="hellobar-closebtn slide-in-close-banner" onClick = {closeBanner} style= {{ color: 'white' }}>+</div>
    // {/* <section className="top-image-holder">
    // </section> */}
    // <section className="text-left" style={{ marginLeft: '0px' }}>
    //     <div className = "mb-4 px-6 py-6 bg-purple-800">
    //         <h2 className= "font-bold text-gray-100 text-xl leading-10">Check Out How Vantage Circle helped Happiest Minds to achieve a <span className = "text-orange">50% rise</span> in Employee Engagement</h2>
    //     </div>
    //     {/* <span className="text-white text-xl font-bold tracking-wide pt-5 p-6">Sign up to our newsletter</span> */}
    //     <div id="subscribe-banner" class="subscribe_wrapper text-white">
    //     <div class = "flex justify-center items-center bottombannerPopUp">
    //         <HubspotForm
    //         portalId='6153052'
    //         formId='e0d0e5d0-e57f-4a36-9f51-c057ddea5a1f'
    //         onSubmit={() => console.log('Submit!')}
    //         onReady={(form) => console.log('Form ready!')}
    //         loading={<div>Loading...</div>}
    //     />

    //     </div>
    //     </div>
    // </section>
    // </div>
    <section style= {{ width : '400px', background: '#fffcf5', boxShadow: '0px 1px 15px rgba(0,0,0,0.2)' }} className= { showBanner ? "xl:block hidden show-slide h-auto z-10 xl:w-3/12 2xl:w-2/12 transform scale-75 origin-bottom-left": "hide-slide h-0 z-10 xl:w-3/12 2xl:w-2/12 transform scale-75 origin-bottom-left"}>
       <div className="hellobar-closebtn slide-in-close-banner z-10" onClick = {closeBanner} style= {{ color: 'black' }}>+</div>
       <div className= "mb-4 px-5 py-0 relative h-56 flex">
          <div className='absolute -bottom-4 right-8/12'>
            <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/image_2023_03_15T10_19_08_300Z-1.png" alt="Vantage Rewards" width="140" height="43" />
          </div>
         <h2 className= "font-bold text-indigo-100 text-2xl text-right mt-12 z-0">A comprehensive Buyer's Guide For <span className = "text-orange">Rewards & Recognition</span><br/> Program</h2>
       </div>
        <section className="text-left bg-indigo-100 pt-5" style={{ marginLeft: '0px' }}>
            {/* <span className="text-white text-xl font-bold tracking-wide pt-5 p-6">Sign up to our newsletter</span> */}
            <div id="subscribe-banner" class="subscribe_wrapper text-white pb-3">
                <div class = "flex justify-center items-center bottombannerPopUp">
                    <HubspotForm
                    portalId='6153052'
                    formId='512704d6-0be6-4292-988f-18fc87966221'
                    onSubmit={() => console.log('Submit!')}
                    onReady={(form) => console.log('Form ready!')}
                    loading={<div>Loading...</div>}
                />

                </div>
            </div>
            {/* <div className='py-5 px-2 flex justify-center bg-purple-800'><a href='/products/rewards-recognition/' className='text-white bg-orange p-4 rounded slideInBtn'>Get to know Vantage Rewards</a></div> */}
            {/* <p className='px-3 text-white text-sm  bg-purple-800'>By using our site, you acknowledge that you have read and understood our <a href= "https://www.vantagecircle.com/privacy-policy/" className = "text-orange">Privacy Policy</a></p> */}
        </section>
    </section>
  )

}

export default BottomBanner