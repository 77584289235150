import React from "react";

const VantageFit = () => (
    <section className="container h-auto my-20 w-full flex flex-col justify-center items-center">
        <div className="bg-purple-100 py-0 relative rounded-lg w-full flex flex-col items-center">
            <div className = 'flex w-5/6 justify-start ml-5'>
                <img className = "transform md:scale-110 lg:scale-150 md:translate-y-1 lg:translate-y-3 transform rotate-180" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1629110522/gatsbycms/uploads/2021/08/half-dotted.png" alt= 'pic'/>
            </div>
            <div className = 'w-5/6 py-5 flex flex-col md:flex-row justify-around items-center'>
                <div className = "md:mr-4">
                    <h1 className = "font-semibold text-center md:text-left text-gray-900 text-4xl">Know About</h1>
                    <h1 className = 'font-semibold text-center md:text-left text-gray-900 text-4xl'>Vantage Fit Pricing</h1>
                    <p className = 'mb-0 mt-5 text-center md:text-left md:mt-3 text-gray-900'>Enrich employee wellbeing at workplace</p>
                    <p className = 'mb-4 mt-0 text-center md:text-left text-gray-900'>with our tailored pricing plans</p>
                    <div className = "w-full flex justify-center md:justify-start"><a href= "https://www.vantagefit.io/pricing/" className = 'bg-red py-2 px-8 rounded-sm mt-2 text-base text-white'>See Pricing</a></div>
                </div>
                <div className = "mt-12 md:mt-3 md:w-1/2 flex justify-end">
                    <img src = 'https://res.cloudinary.com/vantagecircle/image/upload/v1629110523/gatsbycms/uploads/2021/08/vfit-pricing-artwork.png' alt= "pic" />
                </div>
            </div>
            <div className = 'flex w-1/5 justify-start ml-5'>
            <img className = "transform md:scale-110 lg:scale-150 md:-translate-y-1 lg:-translate-y-3" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1629110522/gatsbycms/uploads/2021/08/half-dotted.png" alt = "pic"/>
            </div>
        </div>
    </section>
);

export default VantageFit;